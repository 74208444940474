import { useMemo } from 'react';
import useRouter from '@sift/resift/useRouter';
import queryString from 'query-string';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { pageSizeOptions } from 'store/searchResults';
import usePreserveReference from 'use-preserve-reference';
import useEntityTypeConfig from 'helpers/useEntityTypeConfig';
import getAttributeValuesFromObject from 'pages/SearchResults/_helpers/getAttributeValuesFromObject';

function parseOrDefault(_str: undefined | string | string[], defaultValue: number) {
  if (_str === undefined) {
    return defaultValue;
  }

  const str = Array.isArray(_str) ? _str[0] : _str;
  const parsed = parseInt(str, 10);

  if (isNaN(parsed)) {
    return defaultValue;
  }
  return parsed;
}

export default function useLocationProps(typeKey?: string) {
  const { location } = useRouter();
  const config = useEntityTypeConfig(typeKey);

  const query = usePreserveReference(queryString.parse(location.search));

  const page = parseOrDefault(query.page, 1);
  const pageSize = parseOrDefault(query.pageSize, pageSizeOptions[0]);
  const q = Array.isArray(query.q) ? query.q[0] : query.q;
  const sortBy = Array.isArray(query.sortBy) ? query.sortBy[0] : query.sortBy;
  const sortDirection = Array.isArray(query.sortDirection)
    ? query.sortDirection[0]
    : query.sortDirection;
  const advancedSearch = query.advancedSearch === 'true';
  const queryParams = queryString.parse(location.search);
  const graphType = queryParams.graphType;
  const subTreeRootId = queryParams.subTreeRootId;

  const fields = _get(config, 'fields');

  const filters = useMemo(() => {
    return (
      Object.entries(query)
        // (notes - pearl) 'separated' is an arbitrary value that doesn't exist on config fields
        .filter(([key]) => _has(config, ['fields', key]) || key === 'separated')
        .reduce((acc, [fieldKey, _value]) => {
          const value = _value as string | string[];
          const values = Array.isArray(value) ? value : [value];

          for (const v of values) {
            acc.push({ fieldKey, value: v });
          }

          return acc;
        }, [] as Array<{ fieldKey: string; value: string }>)
    );
  }, [config, query]);

  const selectedAttributeValues = getAttributeValuesFromObject(query, fields || {});

  const locationProps = {
    q,
    sortBy,
    sortDirection,
    advancedSearch,
    page,
    pageSize,
    filters,
    graphType,
    subTreeRootId,
    selectedAttributeValues,
  };

  return usePreserveReference(locationProps);
}

export type LocationProps = ReturnType<typeof useLocationProps>;
